import { Moon, Sun } from "lucide-react";
import { Theme, useTheme } from "remix-themes";

export function ThemeToggle() {
  const [theme, setTheme] = useTheme();
  const isLight = theme === Theme.LIGHT;

  return (
    <button
      onClick={() => setTheme(isLight ? Theme.DARK : Theme.LIGHT)}
      className="absolute right-4 top-6 rounded-md p-2"
    >
      <span className="sr-only">{isLight ? "Change to Dark Mode" : "Change to Light Mode"}</span>
      {isLight ? <Sun /> : <Moon />}
    </button>
  );
}
