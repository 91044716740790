import { Check } from "lucide-react";
import { useIsClient } from "usehooks-ts";

import { cn } from "~/lib/utils";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  completed?: boolean;
  children: React.ReactNode;
}

export function DayButton(props: Props) {
  const isClient = useIsClient();
  const { active, completed, children, ...rest } = props;

  // Loading state
  if (!isClient) {
    return <div className="h-16 w-10 animate-pulse rounded-2xl bg-grey/25"></div>;
  }

  return (
    <button
      className={cn(
        "grid cursor-pointer place-items-center rounded-2xl border-2 border-transparent bg-transparent px-2.5 py-2 text-xs font-medium transition duration-75 disabled:cursor-auto disabled:text-grey sm:text-sm",
        active && "border-white bg-white shadow-brand dark:border-foreground dark:bg-transparent",
      )}
      {...rest}
    >
      <div
        role="progressbar"
        aria-valuenow={completed ? 100 : 0}
        className={cn(
          "mb-1 grid size-5 place-items-center rounded-full border-2 border-current text-current",
          active && "border-primary",
          completed && "border-primary bg-primary text-background",
          rest.disabled && "border-grey text-grey",
        )}
      >
        {completed ? <Check className="size-3" strokeWidth={3.5} /> : null}
      </div>
      <span className="tabular-nums tracking-tighter">{children}</span>
    </button>
  );
}
