import { LoaderFunctionArgs, json } from "@remix-run/node";
import { Link, MetaFunction, NavLink, Outlet, redirect, useLoaderData } from "@remix-run/react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import { z } from "zod";

import { AlbumArt } from "~/components/album-art";
import { DayButton } from "~/components/day-button";
import { ErrorComponent } from "~/components/error-component";
import { PoweredBy } from "~/components/powered-by";
import { ThemeToggle } from "~/components/theme-toggle";
import { useFeedback } from "~/hooks/dialog-context";
import { useEventProgress } from "~/hooks/useEventProgress";
import { CONTENT } from "~/lib/config";
import { Sentry } from "~/lib/sentry";
import { cn } from "~/lib/utils";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const schema = z.object({
  eventId: z.string(),
  eventTitle: z.string(),
  eventStartDate: z.string(),
  eventEndDate: z.string(),
  eventTimezone: z.string(),
  eventDays: z.array(
    z.object({
      title: z.string(),
      subtitle: z.string(),
      date: z.string(),
      songSlug: z.string(),
      videoURL: z.string(),
    }),
  ),
});

export const meta: MetaFunction<typeof loader> = ({ data, params }) => {
  return [{ title: `${data?.eventTitle ?? ""} Daily | ${dayjs(params.date).format("MMM Do")}` }];
};

export async function loader({ params }: LoaderFunctionArgs) {
  const requestedDate = params.date;
  const isDate = z.string().date();
  const dateParseResult = isDate.safeParse(requestedDate);
  const today = dayjs().tz("America/New_York").format("YYYY-MM-DD");
  if (!requestedDate || dayjs(requestedDate).isAfter(dayjs(today)) || !dateParseResult.success) {
    return redirect(`/daily/${today}`);
  }

  // TODO: Fetch content for the days surrounding the requested date
  try {
    const url = new URL(`/api/daily/${requestedDate}`, process.env.API_ENDPOINT);
    const response = await fetch(`${url.toString()}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.API_TOKEN}`,
      },
    });
    if (!response.ok) {
      console.error(response.statusText);
      throw new Response(response.statusText, { status: response.status });
    }

    const data = await response.json();
    const parsedData = schema.safeParse(data);

    if (!parsedData.success) {
      console.error(parsedData.error.errors);
      throw new Response(response.statusText, { status: 500 });
    }

    const headers = new Headers();
    if (process.env.NODE_ENV === "development") {
      headers.set("Cache-Control", "no-store");
    } else {
      headers.set("Cache-Control", "public, max-age=300, s-maxage=300, stale-while-revalidate=60");
    }

    return json(
      {
        isProd: process.env.NODE_ENV === "production",
        ...parsedData.data,
      },
      { headers },
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export default function DailyLayout() {
  const { openFeedbackDialog } = useFeedback();
  const [viewPostEvent, setViewPostEvent] = useState(false);
  const [isListenAgain, setIsListenAgain] = useState(false);
  const { eventStartDate, eventTimezone: tz, eventTitle, eventDays, eventId, isProd } = useLoaderData<typeof loader>();
  const { progress } = useEventProgress(eventId);

  const content = CONTENT.eventFinishedScreen;
  // in EST
  const today = dayjs().tz(tz);
  const eventStart = dayjs.tz(eventStartDate, tz);
  const diff = eventStart.diff(today);
  const daysUntil = Math.ceil(diff / (1000 * 60 * 60 * 24));
  // TODO: Verify this goes to post conference screen at midnight 6-19
  const eventHasStarted = today.isAfter(eventStart);

  // Event finished screen
  if ((eventHasStarted || viewPostEvent) && !isListenAgain) {
    return (
      <div className="relative mx-auto max-w-screen-md px-4 pb-8 pt-2">
        <ThemeToggle />
        <img
          //src={theme === Theme.LIGHT ? "/tgcw24.svg" : "/tgcw24-rose.svg"}
          src="/tgcw24-rose.svg"
          alt={`TGCW24 Logo`}
          className="mx-auto mb-3"
        />
        <div className="flex flex-col items-center gap-y-4">
          <h1 className="text-pretty text-center text-[32px] font-bold leading-[38px] text-primary">{content.title}</h1>
          <p className="text-center">{content.subtitle}</p>
          <div className="mt-4 sm:mt-12">
            <div className="size-64 overflow-hidden rounded-md sm:size-96">
              <AlbumArt />
            </div>
          </div>
          <div className="mt-2 grid w-full max-w-sm grid-cols-2 gap-2 sm:mt-4">
            <a
              href={content.buttons.streamNow.href}
              target="_blank"
              rel="noreferrer"
              className={`flex items-center justify-center whitespace-nowrap rounded-lg border border-primary bg-${content.buttons.streamNow.color} px-2 py-2 text-center font-bold text-${content.buttons.streamNow.color}-foreground`}
            >
              {content.buttons.streamNow.text}
            </a>
            <a
              href={content.buttons.exploreMore.href}
              target="_blank"
              rel="noreferrer"
              className={`flex items-center justify-center whitespace-nowrap rounded-lg border border-primary bg-${content.buttons.exploreMore.color} px-2 py-2 text-center font-bold text-${content.buttons.exploreMore.color}-foreground`}
            >
              {content.buttons.exploreMore.text}
            </a>
            <Link
              to={content.buttons.listenAgain.href}
              onClick={() => setIsListenAgain(true)}
              className={`flex items-center justify-center whitespace-nowrap rounded-lg border border-primary bg-${content.buttons.listenAgain.color} px-2 py-2 text-center font-bold text-${content.buttons.listenAgain.color}-foreground`}
            >
              {content.buttons.listenAgain.text}
            </Link>
            <button
              onClick={openFeedbackDialog}
              className={`flex items-center justify-center whitespace-nowrap rounded-lg border border-primary bg-secondary px-2 py-2 text-center font-bold text-secondary-foreground`}
            >
              {content.buttons.provideFeedback.text}
            </button>
          </div>
          <PoweredBy />
          {!isProd ? (
            <button
              className="rounded p-1 text-center text-sm text-muted-foreground"
              onClick={() => setViewPostEvent((v) => !v)}
            >
              Toggle Post Conference View
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  const daysToDisplay = eventDays.map((d) => ({
    ...d,
    isCompleted: progress.findIndex((p) => p === d.date) !== -1,
  }));

  return (
    <div className="relative mx-auto max-w-screen-md px-4 pb-4 pt-2 sm:pt-8 md:px-5">
      <ThemeToggle />
      <img
        //src={theme === Theme.LIGHT ? "/tgcw24.svg" : "/tgcw24-rose.svg"}
        src="/tgcw24-rose.svg"
        alt={`TGCW24 Logo`}
        className="mx-auto mb-3"
      />
      <h1 className="text-balanced text-center text-[32px] font-bold leading-[38px] text-primary">{`${daysUntil} day${daysUntil === 1 ? "" : "s"} until ${eventTitle}!`}</h1>
      <nav className="mx-auto mt-1.5 max-w-sm">
        <ul className="flex justify-between">
          {daysToDisplay.map((day) => {
            const displayDate = dayjs(day.date).format("M/D");
            const isDisabled = dayjs.tz(day.date, tz).isAfter(today);
            // If it's in the future, I just want to render a button instead of a link, because disabled links break a11y rules
            if (isDisabled) {
              return (
                <li key={day.date}>
                  <DayButton active={false} completed={day.isCompleted} disabled>
                    {dayjs(day.date).format("M/D")}
                  </DayButton>
                </li>
              );
            }

            // NavLinks know when they're active based on the URL -- see https://remix.run/docs/en/main/components/nav-link
            return (
              <li key={day.date}>
                <NavLink
                  to={`/daily/${day.date}`}
                  className={cn("rounded-2xl transition hover:bg-foreground/5 focus-visible:ring")}
                >
                  {({ isActive }) => {
                    return (
                      <DayButton tabIndex={-1} active={isActive} completed={day.isCompleted} disabled={isDisabled}>
                        {displayDate}
                      </DayButton>
                    );
                  }}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <main>
        <Outlet />
        {!isProd ? (
          <button
            className="rounded p-1 text-center text-sm text-muted-foreground"
            onClick={() => setViewPostEvent((v) => !v)}
          >
            Toggle Post Conference View
          </button>
        ) : null}
      </main>
      <PoweredBy />
    </div>
  );
}

export function ErrorBoundary() {
  return <ErrorComponent />;
}
