import { WILogo } from "~/components/icons/wi-logo";

export function PoweredBy() {
  return (
    <div className="mt-4 flex justify-center">
      <a
        className="mx-auto flex items-center gap-1.5 text-center text-xs text-muted-foreground/50"
        href="https://www.theworshipinitiative.com"
        target="_blank"
        rel="noreferrer"
      >
        <span>powered by</span>
        <div className="flex items-center gap-1">
          <WILogo className="size-4 opacity-25" />
          <span className="font-bold uppercase tracking-wide">THE WORSHIP INITIATIVE</span>
        </div>
      </a>
    </div>
  );
}
